import { ChangeEvent, Fragment } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import CheckboxInput, { CheckboxOption } from "../common/CheckboxInput";
import DatePicker from "../common/DatePicker";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import Input from "../common/Input";
import RadioInput from "../common/RadioInput";
import TextArea from "../common/TextArea";

interface MedicationHistoryProps {
  formControl: Control;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const PAGE_TITLE = "Medication History";

const MedicationHistory = ({
  formControl,
  errors,
  register,
  unregister,
  watch,
  setValue,
}: MedicationHistoryProps) => {
  const watchPastPrescribedMedication = watch("pastPrescribedMedication");
  const watchPastPrescribedMedicationDetails = watch(
    "pastPrescribedMedicationDetails"
  );
  const watchIsOnAnticholinergicMedication = watch(
    "isOnAnticholinergicMedication"
  );
  const watchAnticholinergicMedication = watch("anticholinergicMedication");
  const watchAnticholinergicMedicationDetails = watch(
    "anticholinergicMedicationDetails"
  );

  const isOnAnticholinergicMedication =
    watchIsOnAnticholinergicMedication === "yes";

  const getOtherName = (name: string) => {
    return `other${name[0].toUpperCase()}${name.slice(1)}`;
  };

  const onPastMedicationValueChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, name, value } = event.target;

    if (!checked) {
      unregister(`${name}Details.${value}`);
    } else if (value.toLowerCase() === "none") {
      unregister(`${name}Details`);
      setValue(name, ["None"]);
    } else {
      if (watchPastPrescribedMedication) {
        const watchPastMedsArray =
          watchPastPrescribedMedication as Array<string>;

        if (watchPastMedsArray.includes("None")) {
          watchPastMedsArray.push(value);
          setValue(
            name,
            watchPastMedsArray.filter((med) => med.toLowerCase() !== "none")
          );
        }
      }
    }
  };

  const onCheckboxValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name, value } = event.target;

    if (!checked) {
      if (value.toLowerCase() === "other") {
        const splitNames = name.split(".");
        if (splitNames.length > 1) {
          const fullName = splitNames
            .map((splitName, index) => {
              if (index === splitNames.length - 1) {
                return getOtherName(splitName);
              }
              return splitName;
            })
            .join(".");
          unregister(fullName);
        } else {
          unregister(`${getOtherName(name)}`);
        }
      } else {
        unregister(`${name}Details.${value}`);
      }
    }
  };

  const onAnticholinergicMedicationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, name, value } = event.target;

    if (!checked) {
      if (value.toLowerCase() === "other") {
        unregister(getOtherName(name));
      } else {
        unregister(`${name}Details.${value}`);

        if (watchAnticholinergicMedicationDetails) {
          const otherName = getOtherName(value);
          if (watchAnticholinergicMedicationDetails[otherName]) {
            unregister(`${name}Details.${otherName}`);
          }
        }
      }
    }
  };

  const onPastMedicationStoppedChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    if (value?.toLowerCase() === "no") {
      const splitNames = name.split(".");
      if (splitNames.length === 3) {
        const pastMedication =
          watchPastPrescribedMedicationDetails &&
          watchPastPrescribedMedicationDetails[splitNames[1]];

        if (pastMedication) {
          if (pastMedication.stopDate) {
            unregister(`${splitNames[0]}.${splitNames[1]}.stopDate`);
          }
          if (pastMedication.duration) {
            unregister(`${splitNames[0]}.${splitNames[1]}.duration`);
          }
        }
      }
    }
  };

  const displayAnticholinergicConditionalFields = (
    label: string,
    name: string,
    options: CheckboxOption[]
  ) => {
    const includesAnticholinergic =
      watchAnticholinergicMedication &&
      (watchAnticholinergicMedication as Array<string>).includes(name);

    if (includesAnticholinergic) {
      const otherName = getOtherName(name);

      return (
        <>
          <FormField
            label={`Please select appropriate ${label.toLowerCase()}(s)`}
            required={true}
            errors={errors}
            names={[`anticholinergicMedicationDetails.${name}`]}
          >
            <CheckboxInput
              name={`anticholinergicMedicationDetails.${name}`}
              required="This field is required"
              register={register}
              options={options}
              onValueChange={onCheckboxValueChange}
            />
          </FormField>
          {watchAnticholinergicMedicationDetails &&
            watchAnticholinergicMedicationDetails[name] &&
            (
              watchAnticholinergicMedicationDetails[name] as Array<string>
            ).includes("other") && (
              <FormField
                label={`Please specify the other ${label.toLowerCase()}(s)`}
                required={true}
                errors={errors}
                names={[`anticholinergicMedicationDetails.${otherName}`]}
              >
                <Controller
                  name={`anticholinergicMedicationDetails.${otherName}`}
                  control={formControl}
                  defaultValue={""}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => (
                    <Input props={{ ...field, type: "text" }} />
                  )}
                />
              </FormField>
            )}
        </>
      );
    }
    return null;
  };

  return (
    <FormSection title={PAGE_TITLE}>
      <FormField
        label="Which of the following medicines have been prescribed for the patient in the past?"
        required={true}
        errors={errors}
        names={["pastPrescribedMedication"]}
      >
        <CheckboxInput
          name="pastPrescribedMedication"
          required="This field is required"
          register={register}
          onValueChange={onPastMedicationValueChange}
          options={[
            { value: "Oxybutynin", label: "Oxybutynin" },
            { value: "Tolterodine", label: "Tolterodine" },
            { value: "Trospium", label: "Trospium" },
            { value: "Solifenacin", label: "Solifenacin" },
            { value: "Darifenacin", label: "Darifenacin" },
            { value: "Propiverine", label: "Propiverine" },
            { value: "Other", label: "Other" },
            { value: "None", label: "None" },
          ]}
        />
      </FormField>
      {watchPastPrescribedMedication &&
        (watchPastPrescribedMedication as Array<string>).map((medication) => {
          const isOther = medication.toLowerCase() === "other";

          if (medication.toLowerCase() === "none") {
            return null;
          }

          return (
            <Fragment key={medication}>
              {isOther && (
                <FormField
                  label={`Please provide medication name`}
                  required={true}
                  errors={errors}
                  names={[`pastPrescribedMedicationDetails.${medication}.name`]}
                >
                  <Controller
                    name={`pastPrescribedMedicationDetails.${medication}.name`}
                    control={formControl}
                    defaultValue={""}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <Input
                        props={{
                          ...field,
                          type: "text",
                          placeholder: "Please specify",
                          className: "w-64",
                        }}
                      />
                    )}
                  />
                </FormField>
              )}
              <FormField
                label={`When was ${
                  isOther ? "this medication" : medication
                } first initiated?`}
                required={true}
                errors={errors}
                names={[
                  `pastPrescribedMedicationDetails.${medication}.dateInitiated`,
                ]}
              >
                <DatePicker
                  name={`pastPrescribedMedicationDetails.${medication}.dateInitiated`}
                  formControl={formControl}
                  register={register}
                  setValue={setValue}
                  required={"This field is required"}
                />
              </FormField>
              <FormField
                label={`Has ${
                  isOther ? "this medication" : medication
                } been stopped?`}
                required={true}
                errors={errors}
                names={[
                  `pastPrescribedMedicationDetails.${medication}.stopped`,
                ]}
              >
                <RadioInput
                  name={`pastPrescribedMedicationDetails.${medication}.stopped`}
                  required="This field is required"
                  register={register}
                  onValueChange={onPastMedicationStoppedChange}
                  options={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                  ]}
                />
              </FormField>
              {watchPastPrescribedMedicationDetails &&
                watchPastPrescribedMedicationDetails[medication]?.stopped ===
                  "yes" && (
                  <>
                    <FormField
                      label={`On which date was ${
                        isOther ? "this medication" : medication
                      } stopped?`}
                      required={true}
                      errors={errors}
                      names={[
                        `pastPrescribedMedicationDetails.${medication}.stopDate`,
                      ]}
                    >
                      <DatePicker
                        name={`pastPrescribedMedicationDetails.${medication}.stopDate`}
                        formControl={formControl}
                        register={register}
                        setValue={setValue}
                        required={"This field is required"}
                      />
                    </FormField>
                    <FormField
                      label={`Duration of ${
                        isOther ? "this medication" : medication
                      }`}
                      required={true}
                      errors={errors}
                      names={[
                        `pastPrescribedMedicationDetails.${medication}.duration`,
                      ]}
                    >
                      <Controller
                        name={`pastPrescribedMedicationDetails.${medication}.duration`}
                        control={formControl}
                        defaultValue={""}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Input
                            props={{
                              ...field,
                              type: "text",
                              placeholder: "e.g. 3 Months",
                            }}
                          />
                        )}
                      />
                    </FormField>
                    <FormField
                      label={`Why was ${
                        isOther ? "this medication" : medication
                      } stopped?`}
                      required={true}
                      errors={errors}
                      names={[
                        `pastPrescribedMedicationDetails.${medication}.reasonsForStopping`,
                      ]}
                    >
                      <CheckboxInput
                        name={`pastPrescribedMedicationDetails.${medication}.reasonsForStopping`}
                        required="This field is required"
                        register={register}
                        onValueChange={onCheckboxValueChange}
                        options={[
                          {
                            value: "treatmentFailure",
                            label: "Treatment Failure",
                          },
                          {
                            value: "adverseReactions",
                            label: "Adverse reactions",
                          },
                          { value: "Other", label: "Other" },
                        ]}
                      />
                    </FormField>
                    {watchPastPrescribedMedicationDetails &&
                      watchPastPrescribedMedicationDetails[medication]
                        .reasonsForStopping &&
                      (
                        watchPastPrescribedMedicationDetails[medication]
                          .reasonsForStopping as Array<string>
                      ).includes("treatmentFailure") && (
                        <FormField
                          label={`Treatment failure related to ${
                            isOther ? "this medication" : medication
                          }`}
                          required={true}
                          errors={errors}
                          names={[
                            `pastPrescribedMedicationDetails.${medication}.reasonsForStoppingDetails.treatmentFailure`,
                          ]}
                        >
                          <CheckboxInput
                            name={`pastPrescribedMedicationDetails.${medication}.reasonsForStoppingDetails.treatmentFailure`}
                            required="This field is required"
                            register={register}
                            options={[
                              {
                                value: "Insufficient efficacy",
                                label: "Insufficient efficacy",
                              },
                              {
                                value:
                                  "Poor adherence due to intolerable side effects",
                                label:
                                  "Poor adherence due to intolerable side effects",
                              },
                            ]}
                          />
                        </FormField>
                      )}
                    {watchPastPrescribedMedicationDetails &&
                      watchPastPrescribedMedicationDetails[medication]
                        .reasonsForStopping &&
                      (
                        watchPastPrescribedMedicationDetails[medication]
                          .reasonsForStopping as Array<string>
                      ).includes("adverseReactions") && (
                        <FormField
                          label={`Adverse reactions related to ${
                            isOther ? "this medication" : medication
                          }`}
                          required={true}
                          errors={errors}
                          names={[
                            `pastPrescribedMedicationDetails.${medication}.reasonsForStoppingDetails.adverseReactions`,
                          ]}
                        >
                          <CheckboxInput
                            name={`pastPrescribedMedicationDetails.${medication}.reasonsForStoppingDetails.adverseReactions`}
                            required="This field is required"
                            register={register}
                            options={[
                              {
                                value: "Urinary retention",
                                label: "Urinary retention",
                              },
                              {
                                value: "Constipation",
                                label: "Constipation",
                              },
                              {
                                value: "Blurred vision",
                                label: "Blurred vision",
                              },
                              {
                                value: "Cognitive impairment",
                                label: "Cognitive impairment",
                              },
                              { value: "Dry eyes", label: "Dry eyes" },
                              {
                                value: "Orthostatic hypotension",
                                label: "Orthostatic hypotension",
                              },
                              { value: "Tachycardia", label: "Tachycardia" },
                              { value: "Dry mouth", label: "Dry mouth" },
                              { value: "Sedation", label: "Sedation" },
                              { value: "Dyspepsia", label: "Dyspepsia" },
                              { value: "Headache", label: "Headache" },
                            ]}
                          />
                        </FormField>
                      )}
                    {watchPastPrescribedMedicationDetails &&
                      watchPastPrescribedMedicationDetails[medication]
                        .reasonsForStopping &&
                      (
                        watchPastPrescribedMedicationDetails[medication]
                          .reasonsForStopping as Array<string>
                      ).includes("Other") && (
                        <FormField
                          label={`Please specify any other reasons for stopping further treatment with ${
                            isOther ? "this medication" : medication
                          }`}
                          required={true}
                          errors={errors}
                          names={[
                            `pastPrescribedMedicationDetails.${medication}.otherReasonsForStopping`,
                          ]}
                        >
                          <Controller
                            name={`pastPrescribedMedicationDetails.${medication}.otherReasonsForStopping`}
                            control={formControl}
                            defaultValue={""}
                            rules={{ required: "This field is required" }}
                            render={({ field }) => (
                              <TextArea props={{ ...field }} />
                            )}
                          />
                        </FormField>
                      )}
                  </>
                )}
            </Fragment>
          );
        })}
      <FormField
        label="Is the patient on other medicines which may worsen the mental deterioration caused by anticholinergic burden? (A number of medications have anticholinergic effects and their cumulative effects on cognition should be considered)"
        required={true}
        errors={errors}
        names={["isOnAnticholinergicMedication"]}
      >
        <RadioInput
          name="isOnAnticholinergicMedication"
          required="This field is required"
          register={register}
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
        />
      </FormField>
      {isOnAnticholinergicMedication && (
        <>
          <FormField
            label="Which of the following is the patient currently taking/ recently stopped taking?"
            required={true}
            errors={errors}
            names={["anticholinergicMedication"]}
          >
            <CheckboxInput
              name="anticholinergicMedication"
              required="This field is required"
              register={register}
              onValueChange={onAnticholinergicMedicationChange}
              options={[
                { value: "antidepressants", label: "Antidepressants" },
                {
                  value: "firstGenerationAntihistamines",
                  label: "First generation antihistamines",
                },
                { value: "muscleRelaxants", label: "Muscle relaxants" },
                { value: "antiarrhythmics", label: "Antiarrhythmics" },
                { value: "antipsychotics", label: "Antipsychotics" },
                { value: "antispasmodics", label: "Antispasmodics" },
                { value: "antiemetics", label: "Antiemetics" },
                { value: "other", label: "Other" },
              ]}
            />
          </FormField>
          {displayAnticholinergicConditionalFields(
            "Antidepressant",
            "antidepressants",
            [
              { value: "amitriptyline", label: "Amitriptyline" },
              { value: "clomipramine", label: "Clomipramine" },
              { value: "imipramine", label: "Imipramine" },
              { value: "paroxetine", label: "Paroxetine" },
              { value: "trimipramine", label: "Trimipramine" },
              {
                value: "other",
                label:
                  "Other antidepressant with anticholinergic effects not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "First generation antihistamine",
            "firstGenerationAntihistamines",
            [
              { value: "chlorpheniramine", label: "Chlorpheniramine" },
              { value: "clemastine", label: "Clemastine" },
              { value: "cyproheptadine", label: "Cyproheptadine" },
              {
                value: "diphenhydramine* (oral)",
                label: "Diphenhydramine* (oral)",
              },
              { value: "doxylamine", label: "Doxylamine" },
              { value: "hydroxyzine", label: "Hydroxyzine" },
              { value: "promethazine", label: "Promethazine" },
              {
                value: "other",
                label:
                  "Other first generation antihistamines not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "Muscle relaxant",
            "muscleRelaxants",
            [
              { value: "cyclobenzaprine", label: "Cyclobenzaprine" },
              { value: "methocarbamol", label: "Methocarbamol" },
              {
                value: "orphenadrine citrate",
                label: "Orphenadrine citrate",
              },
              {
                value: "other",
                label: "Other muscle relaxants not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "Antiarrhythmic",
            "antiarrhythmics",
            [
              { value: "disopyramide", label: "Disopyramide" },
              {
                value: "other",
                label: "Other antiarrhythmics not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "Antipsychotic",
            "antipsychotics",
            [
              { value: "chlorpromazine", label: "Chlorpromazine" },
              { value: "clozapine", label: "Clozapine" },
              { value: "olanzapine", label: "Olanzapine" },
              {
                value: "trifluoperazine",
                label: "Trifluoperazine",
              },
              {
                value: "other",
                label: "Other antipsychotics not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "Antispasmodic",
            "antispasmodics",
            [
              { value: "atropine", label: "Atropine" },
              { value: "belladonna Alkaloids", label: "Belladonna Alkaloids" },
              {
                value: "clidinium-chlordiazepoxide",
                label: "Clidinium-chlordiazepoxide",
              },
              {
                value: "hyoscine Butylbromide",
                label: "Hyoscine Butylbromide",
              },
              { value: "propantheline", label: "Propantheline" },
              {
                value: "other",
                label: "Other antispasmodics not mentioned above",
              },
            ]
          )}
          {displayAnticholinergicConditionalFields(
            "Antiemetic",
            "antiemetics",
            [
              { value: "prochlorperazine", label: "Prochlorperazine" },
              { value: "promethazine", label: "Promethazine" },
              {
                value: "other",
                label: "Other antiemetics not mentioned above",
              },
            ]
          )}
          {watchAnticholinergicMedication &&
            (watchAnticholinergicMedication as Array<string>).includes(
              "other"
            ) && (
              <FormField
                label="Please specify other medicine"
                required={true}
                errors={errors}
                names={["otherAnticholinergicMedication"]}
              >
                <Controller
                  name="otherAnticholinergicMedication"
                  control={formControl}
                  defaultValue={""}
                  rules={{ required: "This field is required" }}
                  render={({ field }) => <TextArea props={{ ...field }} />}
                />
              </FormField>
            )}
        </>
      )}
      <FormField
        label="My patient’s ideal Betmiga prescription is:"
        required={true}
        errors={errors}
        names={["idealBetmigaPrescription"]}
      >
        <RadioInput
          name="idealBetmigaPrescription"
          required="This field is required"
          register={register}
          options={[
            { value: "50mg", label: "Betmiga 50mg po dly" },
            { value: "25mg", label: "Betmiga 25mg po dly" },
          ]}
        />
      </FormField>
      <div className="my-8 text-xs sm:text-sm">
        BETMIGA&trade; 25mg po dly is recommended for patients with moderate
        hepatic impairment (Child-Pugh Class B) and/or severe renal impairment
        (eGFR 15-29 ml/min/1.73m2)
      </div>
      <FormField
        label="Please select which additional material would support your motivation"
        required={true}
        errors={errors}
        names={["additionalMaterial"]}
      >
        <CheckboxInput
          name="additionalMaterial"
          required="This field is required"
          register={register}
          options={[
            {
              value: "EfficacyAndTolerabilityOfMirabegron.pdf",
              label: "Efficacy and tolerability of Mirabegron",
            },
            {
              value: "ClinicalUseOfMirabegron.pdf",
              label: "Clinical use of Mirabegron",
            },
            {
              value:
                "AdherenceAndTolerabilityStudiesToSupportSuitabilityOfMirabegron.pdf",
              label:
                "Adherence and tolerability studies to support suitability of Mirabegron",
            },
          ]}
        />
      </FormField>
    </FormSection>
  );
};

export default MedicationHistory;
