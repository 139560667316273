import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import Input from "../common/Input";
import Select from "../common/Select";

interface QualityOfLifeProps {
  formControl: Control;
  errors: FieldErrors<FieldValues>;
}

const PAGE_TITLE = "Quality of life assessment";

const subtitle =
  "(In relation to symptoms that should be otherwise controlled by the current treatment)";

const QualityOfLife = ({ formControl, errors }: QualityOfLifeProps) => {
  return (
    <FormSection title={PAGE_TITLE} subtitle={subtitle}>
      <FormField
        label="Patient's symptoms are:"
        required={true}
        errors={errors}
        names={["impactOfSymptoms"]}
      >
        <Controller
          name="impactOfSymptoms"
          control={formControl}
          defaultValue={""}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Select
              props={{ ...field }}
              options={[
                { value: "manageable", label: "Manageable" },
                { value: "inconvenient", label: "Inconvenient" },
                {
                  value: "severely affecting their life",
                  label: "Severely affecting their life",
                },
              ]}
            />
          )}
        />
      </FormField>
      <FormField
        label="Times of voiding"
        required={true}
        errors={errors}
        names={["timesOfVoiding"]}
      >
        <Controller
          name="timesOfVoiding"
          control={formControl}
          defaultValue={""}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Select
              props={{ ...field }}
              options={[
                {
                  value: "pollakiuria",
                  label: "Mostly during the day (Pollakiuria)",
                },
                {
                  value: "nocturia",
                  label: "At night-time (Nocturia)",
                },
                {
                  value: "polyuria",
                  label: "During the day and night (Polyuria)",
                },
              ]}
            />
          )}
        />
      </FormField>
      <FormField
        label="What is the best fitting description of patient’s voided volumes each time:"
        required={true}
        errors={errors}
        names={["voidedVolume"]}
      >
        <Controller
          name="voidedVolume"
          control={formControl}
          defaultValue={""}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Select
              props={{ ...field }}
              options={[
                { value: "drops", label: "Drops" },
                { value: "small volumes", label: "Small volumes" },
                { value: "large volumes", label: "Large volumes" },
              ]}
            />
          )}
        />
      </FormField>
      <FormField
        label="Number of pads used daily"
        required={true}
        errors={errors}
        names={["dailyNumberOfPads"]}
      >
        <Controller
          name="dailyNumberOfPads"
          control={formControl}
          defaultValue={""}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Input
              props={{ ...field, type: "number", placeholder: "e.g. 23" }}
            />
          )}
        />
      </FormField>
    </FormSection>
  );
};

export default QualityOfLife;
