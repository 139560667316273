interface LoaderProps {
  size?: number; // this will be the width and height of the loader
}

const Loader = ({ size = 100 }: LoaderProps) => {
  return (
    <div className="w-full flex items-center justify-center">
      <div
        className={`h-4 w-4 sm:h-6 sm:w-6 bg-transparent border-2 border-b-transparent border-white rounded-full animate-spin`}
      />
    </div>
  );
};

export default Loader;
