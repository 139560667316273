import { useEffect } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import CheckboxInput from "../common/CheckboxInput";
import DatePicker from "../common/DatePicker";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import Input from "../common/Input";
import RadioInput from "../common/RadioInput";
import Select from "../common/Select";
import TextArea from "../common/TextArea";

interface ClinicalDetailsProps {
  formControl: Control;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
}

const PAGE_TITLE = "Clinical Details";
const MIN_COGNITIVE_IMPAIRMENT_AGE = 66;

const subtitle =
  "Attach any relevant supporting documents, e.g. pathology tests, scans, proof of previous harm or adverse events, treatment failure, etc.";

const ClinicalDetails = ({
  formControl,
  errors,
  watch,
  register,
  getValues,
  setValue,
  unregister,
}: ClinicalDetailsProps) => {
  const patientAge = getValues("age");

  const watchDiagnosis = watch("diagnosis");
  const watchPreviouslyHospitalisedForCondition = watch(
    "previouslyHospitalisedForCondition"
  );
  const watchCauseOfOAB = watch("causeOfOAB");
  const watchNeurologicalCause = watch("neurologicalCause");
  const watchCognitiveImpairment = watch("cognitiveImpairment");
  const watchPatientSymptoms = watch("patientSymptoms");
  const watchTypeOfUI = watch("typeOfUI");
  const watchPredominantIncontinence = watch("predominantIncontinence");
  const watchDateOfDiagnosis = watch("dateOfDiagnosis");
  const watchDateOfPreviousHospitalisation = watch(
    "dateOfPreviousHospitalisation"
  );

  const isOABDiagnosis = watchDiagnosis === "Over Active Bladder (OAB)";
  const isUIDiagnosis = watchDiagnosis === "Urinary Incontinence (UI)";
  const hasBeenPreviouslyHospitalisedForCondition =
    watchPreviouslyHospitalisedForCondition === "yes";
  const isNeurologicalOABCause = watchCauseOfOAB === "neurological cause";
  const isOtherNeurologicalCause = watchNeurologicalCause === "other";
  const hasCognitiveImpairment = watchCognitiveImpairment === "yes";
  const patientHasOtherSymptoms =
    watchPatientSymptoms &&
    (watchPatientSymptoms as Array<string>).includes("other");

  useEffect(() => {
    if (
      watchPreviouslyHospitalisedForCondition?.toLowerCase() === "no" &&
      watchDateOfPreviousHospitalisation
    ) {
      unregister("dateOfPreviousHospitalisation");
    }
  }, [watchPreviouslyHospitalisedForCondition]);

  return (
    <FormSection title={PAGE_TITLE} subtitle={subtitle}>
      <FormField
        label="Diagnosis"
        required={true}
        errors={errors}
        names={["diagnosis"]}
      >
        <Controller
          name="diagnosis"
          control={formControl}
          defaultValue={""}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Select
              props={{ ...field }}
              options={[
                {
                  value: "Urinary Incontinence (UI)",
                  label: "Urinary Incontinence (UI)",
                },
                {
                  value: "Over Active Bladder (OAB)",
                  label: "Over Active Bladder (OAB)",
                },
              ]}
            />
          )}
        />
      </FormField>
      <FormField
        label="Date of diagnosis"
        errors={errors}
        names={["dateOfDiagnosis"]}
        required={true}
      >
        <DatePicker
          name="dateOfDiagnosis"
          defaultValue={watchDateOfDiagnosis}
          register={register}
          setValue={setValue}
          required={"This field is required"}
          formControl={formControl}
        />
      </FormField>
      <FormField
        label="Has this patient been hospitalised in the past 12 months for this condition?"
        required={true}
        errors={errors}
        names={["previouslyHospitalisedForCondition"]}
      >
        <RadioInput
          name="previouslyHospitalisedForCondition"
          required="This field is required"
          register={register}
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
        />
      </FormField>
      {hasBeenPreviouslyHospitalisedForCondition && (
        <FormField
          label="Please specify previous hospitalisation date"
          required={true}
          errors={errors}
          names={["dateOfPreviousHospitalisation"]}
        >
          <DatePicker
            name="dateOfPreviousHospitalisation"
            formControl={formControl}
            defaultValue={watchDateOfPreviousHospitalisation}
            register={register}
            setValue={setValue}
            required={"This field is required"}
          />
        </FormField>
      )}
      {isUIDiagnosis && (
        <>
          <FormField
            label="Please specify type of UI"
            required={true}
            errors={errors}
            names={["typeOfUI"]}
          >
            <RadioInput
              name="typeOfUI"
              required="This field is required"
              register={register}
              options={[
                { value: "stress", label: "Stress" },
                { value: "urgency", label: "Urgency" },
                {
                  value: "mixed incontinence",
                  label: "Mixed incontinence",
                },
              ]}
            />
          </FormField>
          {watchTypeOfUI === "mixed incontinence" && (
            <FormField
              label="Which is the predominant type of incontinence"
              required={true}
              errors={errors}
              names={["predominantIncontinence"]}
            >
              <RadioInput
                name="predominantIncontinence"
                required="This field is required"
                register={register}
                options={[
                  { value: "stress", label: "Stress" },
                  { value: "urgency", label: "Urgency" },
                ]}
              />
            </FormField>
          )}
          {(watchTypeOfUI === "stress" ||
            watchPredominantIncontinence === "stress") && (
            <div className="my-4 text-xs sm:text-sm">
              Mirabegron is not indicated for stress incontinence and this may
              affect the outcome of your motivation
            </div>
          )}
        </>
      )}
      {isOABDiagnosis && (
        <FormField
          label="Please specify cause of OAB"
          required={true}
          errors={errors}
          names={["causeOfOAB"]}
        >
          <RadioInput
            name="causeOfOAB"
            required="This field is required"
            register={register}
            options={[
              {
                value: "detrusor overactivity",
                label: "Detrusor overactivity",
              },
              { value: "neurological cause", label: "Neurological cause" },
            ]}
          />
        </FormField>
      )}
      {isNeurologicalOABCause && (
        <FormField
          label="Please specify neurological cause"
          required={true}
          errors={errors}
          smallErrorMessageOffset={true}
          names={["neurologicalCause", "otherNeurologicalCause"]}
        >
          <RadioInput
            name="neurologicalCause"
            required="This field is required"
            register={register}
            options={[
              {
                value: "Multiple sclerosis",
                label: "Multiple sclerosis",
              },
              {
                value: "Parkinson’s disease",
                label: "Parkinson’s disease",
              },
              { value: "Quadriplegia", label: "Quadriplegia" },
              { value: "Paraplegia", label: "Paraplegia" },
              { value: "Spina Bifida", label: "Spina Bifida" },
              { value: "other", label: "Other" },
            ]}
          />
          {isOtherNeurologicalCause && (
            <Controller
              name="otherNeurologicalCause"
              control={formControl}
              defaultValue={""}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Input
                  props={{
                    ...field,
                    type: "text",
                    placeholder: "Please specify",
                    className: "ml-8",
                  }}
                />
              )}
            />
          )}
        </FormField>
      )}
      {patientAge >= MIN_COGNITIVE_IMPAIRMENT_AGE && (
        <FormField
          label="Is there any underlying or overt cognitive impairment?"
          required={true}
          errors={errors}
          names={["cognitiveImpairment"]}
        >
          <RadioInput
            name="cognitiveImpairment"
            required="This field is required"
            register={register}
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
          />
        </FormField>
      )}
      {hasCognitiveImpairment && (
        <FormField
          label="Please select signs and symptoms experienced by this patient"
          required={true}
          errors={errors}
          names={["patientSymptoms"]}
        >
          <CheckboxInput
            name="patientSymptoms"
            required="This field is required"
            register={register}
            options={[
              { value: "irritability", label: "Irritability" },
              { value: "anxiety", label: "Anxiety" },
              { value: "depression", label: "Depression" },
              { value: "aggression", label: "Aggression" },
              { value: "other", label: "Other" },
            ]}
          />
        </FormField>
      )}
      {patientHasOtherSymptoms && (
        <FormField
          label="Please specify other signs and symptoms not mentioned above"
          required={true}
          errors={errors}
          names={["otherPatientSymptoms"]}
        >
          <Controller
            name="otherPatientSymptoms"
            control={formControl}
            defaultValue={""}
            rules={{ required: "This field is required" }}
            render={({ field }) => <TextArea props={{ ...field }} />}
          />
        </FormField>
      )}
    </FormSection>
  );
};

export default ClinicalDetails;
