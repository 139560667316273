import { HTMLProps } from "react";

export interface SelectOption {
  value: string | number;
  label: string;
}

interface SelectProps {
  options: SelectOption[];
  props: HTMLProps<HTMLSelectElement>;
}

const Select = ({ options, props }: SelectProps) => {
  return (
    <div className="relative sm:max-w-fit">
      <select
        {...props}
        className="bg-white border border-gray-300 p-2 text-xs sm:text-sm w-full sm:w-56 rounded-md focus:outline-primary appearance-none"
      >
        <option value="" disabled />
        {options.map((option: SelectOption) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 m-2 right-0 pointer-events-none bg-white">
        <svg
          className="fill-current h-4 sm:h-5 w-4 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
        >
          <path d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z" />
        </svg>
      </div>
    </div>
  );
};

export default Select;
