import { ReactNode } from "react";
import HorizontalDivider from "./HorizontalDivider";

interface FormSectionProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

const FormSection = ({ title, subtitle, children }: FormSectionProps) => {
  return (
    <>
      <p
        className={`text-lg sm:text-xl lg:text-2xl font-semibold ${
          subtitle ? "mt-6 my-2" : "my-6"
        }`}
      >
        {title}
      </p>
      {subtitle && <p className="mt-2 mb-6 text-xs sm:text-sm">{subtitle}</p>}
      <HorizontalDivider />
      {children}
    </>
  );
};

export default FormSection;
