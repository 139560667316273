import { useEffect, useMemo } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import RadioInput from "../common/RadioInput";

interface PatientEligibilityProps {
  watch: UseFormWatch<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  resetField: UseFormResetField<FieldValues>;
  register: UseFormRegister<FieldValues>;
}

const PAGE_TITLE = "Patient Eligibility";

const PatientEligibility = ({
  watch,
  errors,
  setError,
  clearErrors,
  resetField,
  register,
}: PatientEligibilityProps) => {
  const watchUncontrolledHypertension = watch("hasUncontrolledHypertension");
  const watchEGFR = watch("eGFR");
  const watchHepaticImpairment = watch("hepaticImpairment");

  const hasUncontrolledHypertension = useMemo(
    () => watchUncontrolledHypertension === "Yes",
    [watchUncontrolledHypertension]
  );
  const hasEndStageRenalDisease = useMemo(
    () => watchEGFR === "< 15ml/min//1.73m2",
    [watchEGFR]
  );
  const hasSevereHepaticImpairment = useMemo(
    () =>
      watchHepaticImpairment ===
      "severe hepatic impairment (Child Pugh class C)",
    [watchHepaticImpairment]
  );

  const isNotRecommended =
    hasUncontrolledHypertension ||
    hasEndStageRenalDisease ||
    hasSevereHepaticImpairment;

  useEffect(() => {
    if (hasUncontrolledHypertension) {
      if (!!watchEGFR) {
        resetField("eGFR");
      }
      if (!!watchHepaticImpairment) {
        resetField("hepaticImpairment");
      }
    }
  }, [hasUncontrolledHypertension]);

  useEffect(() => {
    if (hasEndStageRenalDisease) {
      if (!!watchHepaticImpairment) {
        resetField("hepaticImpairment");
      }
    }
  }, [hasEndStageRenalDisease]);

  useEffect(() => {
    if (isNotRecommended && !errors.notRecommended) {
      setError("notRecommended", {
        message: "BETMIGA™ is not recommended for patient.",
      });
    } else if (errors.notRecommended) {
      clearErrors("notRecommended");
    }
  }, [isNotRecommended, errors]);

  return (
    <FormSection title={PAGE_TITLE}>
      <div className="my-4 text-xs sm:text-sm">
        BETMIGA&trade; 25 mg and BETMIGA&trade; 50 mg tablet active ingredient,
        Mirabegron is a selective beta 3-adrenoceptor agonist.
      </div>
      <div className="my-4 text-xs sm:text-sm">
        Please answer the following questions to determine if your patient is
        eligible for BETMIGA&trade;.
      </div>
      <div className="mt-10">
        <FormField
          label="Does the patient have uncontrolled hypertension ≥ 180/110mm Hg?"
          required={true}
          errors={errors}
          names={["hasUncontrolledHypertension"]}
        >
          <RadioInput
            name="hasUncontrolledHypertension"
            required="This field is required"
            register={register}
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
          />
        </FormField>
        {!hasUncontrolledHypertension && (
          <>
            <FormField
              label="What is the patient's most recent eGFR?"
              required={true}
              errors={errors}
              names={["eGFR"]}
            >
              <RadioInput
                name="eGFR"
                required="This field is required"
                register={register}
                options={[
                  {
                    value: "30-89 ml/min/1.73m2",
                    label: "30-89 ml/min/1.73m2",
                  },
                  {
                    value: "15 - 29 ml/min/1.73m2",
                    label: "15 - 29 ml/min/1.73m2",
                  },
                  {
                    value: "< 15ml/min//1.73m2",
                    label: "< 15ml/min//1.73m2",
                  },
                ]}
              />
            </FormField>
            {!hasEndStageRenalDisease && (
              <FormField
                label="What is the patient's most recent hepatic impairment determination?"
                required={true}
                errors={errors}
                names={["hepaticImpairment"]}
              >
                <RadioInput
                  name="hepaticImpairment"
                  required="This field is required"
                  register={register}
                  options={[
                    {
                      value: "no hepatic impairment",
                      label: "No hepatic impairment",
                    },
                    {
                      value: "mild hepatic impairment (Child Pugh class A)",
                      label: "Mild hepatic impairment (Child Pugh class A)",
                    },
                    {
                      value: "moderate hepatic impairment (Child Pugh class B)",
                      label: "Moderate hepatic impairment (Child Pugh class B)",
                    },
                    {
                      value: "severe hepatic impairment (Child Pugh class C)",
                      label: "Severe hepatic impairment (Child Pugh class C)",
                    },
                  ]}
                />
              </FormField>
            )}
          </>
        )}
      </div>
      {hasUncontrolledHypertension && (
        <div className="my-4 text-xs sm:text-sm">
          BETMIGA&trade; has not been studied in patients with severe
          hypertension: ≥ 180/110mm Hg and, therefore, it is not recommended for
          use in this patient population. Should you wish to continue with this
          motivation and you require more information, please contact Astellas
          Medical Affairs, email:{" "}
          <a href="mailto:evelyn.lai@astellas.com">evelyn.lai@astellas.com</a>.
        </div>
      )}
      {hasEndStageRenalDisease && !hasUncontrolledHypertension && (
        <div className="my-4 text-xs sm:text-sm">
          BETMIGA&trade; has not been studied in patients with End Stage Renal
          Disease (eGFR &lt; 15ml/min/1.73m2 or patients requiring
          haemodialysis) and, therefore, it is not recommended for use in this
          patient population. Should you wish to continue with this motivation
          and you require more information, please contact Astellas Medical
          Affairs, email:{" "}
          <a href="mailto:evelyn.lai@astellas.com">evelyn.lai@astellas.com</a>.
        </div>
      )}
      {hasSevereHepaticImpairment &&
        !hasEndStageRenalDisease &&
        !hasUncontrolledHypertension && (
          <div className="my-4 text-xs sm:text-sm">
            BETMIGA&trade; has not been studied in patients with severe hepatic
            impairment (Child-Pugh Class C) and, therefore, it is not
            recommended for use in this patient population. Should you wish to
            continue with this motivation and you require more information,
            please contact Astellas Medical Affairs, email:{" "}
            <a href="mailto:evelyn.lai@astellas.com">evelyn.lai@astellas.com</a>
            .
          </div>
        )}
      {isNotRecommended && (
        <div className="text-xs sm:text-sm mb-4">Thank You</div>
      )}
    </FormSection>
  );
};

export default PatientEligibility;
