import { ChangeEvent } from "react";
import { ChangeHandler, FieldValues, UseFormRegister } from "react-hook-form";

export interface RadioOption {
  value: string | number;
  label: string;
}

interface RadioInputProps {
  options: RadioOption[];
  name: string;
  register: UseFormRegister<FieldValues>;
  onValueChange?(event: ChangeEvent<HTMLInputElement>): void;
  required?: string | boolean;
}

// "react-hook-form" controller doesn't work too great with radio buttons
// as the selected value is lost if the radio buttons are hidden. Rather
// register manually
const RadioInput = ({
  options,
  register,
  name,
  required = false,
  onValueChange,
}: RadioInputProps) => {
  const handleOnChange = async (
    event: ChangeEvent<HTMLInputElement>,
    onChange: ChangeHandler
  ) => {
    if (onValueChange) {
      onValueChange(event);
    }
    await onChange(event);
  };

  const registerInput = (option: RadioOption) => {
    const { onChange, ...otherFields } = register(name, { required });
    return (
      <input
        {...otherFields}
        onChange={(event) => handleOnChange(event, onChange)}
        id={`${name}-${option.value}`}
        type={"radio"}
        value={option.value}
        className="appearance-none rounded-full mt-0 sm:mt-0.5 h-4 w-4 border border-gray-300 bg-white checked:border-4 checked:border-primary focus:outline-primary transition duration-200 cursor-pointer"
      />
    );
  };

  return (
    <div className="flex flex-col pl-2 min-w-[14rem]">
      {options.map((option: RadioOption) => (
        <div className="flex items-start my-1" key={option.value}>
          {registerInput(option)}
          <label
            className="pl-2 text-xs sm:text-sm cursor-pointer p-0"
            htmlFor={`${name}-${option.value}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioInput;
