import { HTMLProps } from "react";
import { ControllerRenderProps } from "react-hook-form";

interface TextAreaProps {
  props: HTMLProps<HTMLTextAreaElement> & ControllerRenderProps;
}

const TextArea = ({ props }: TextAreaProps) => {
  return (
    <textarea
      {...props}
      className={
        "w-full sm:w-8/12 border border-gray-400 h-24 resize-none p-1 text-xs sm:text-sm"
      }
    />
  );
};

export default TextArea;
