import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import RadioInput from "../common/RadioInput";
import Input from "../common/Input";
import Select from "../common/Select";

interface PatientDetailsProps {
  formControl: Control;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
}

const PAGE_TITLE = "Patient Details";

const PatientDetails = ({
  formControl,
  errors,
  register,
}: PatientDetailsProps) => {
  return (
    <FormSection title={PAGE_TITLE}>
      <div className="flex flex-col items-center sm:items-start w-full">
        <FormField
          label="Patient Title"
          required={true}
          errors={errors}
          names={["patientTitle"]}
        >
          <Controller
            name="patientTitle"
            control={formControl}
            defaultValue={""}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <Select
                props={{ ...field }}
                options={[
                  { value: "Miss", label: "Miss" },
                  { value: "Ms", label: "Ms" },
                  { value: "Mrs", label: "Mrs" },
                  { value: "Mr", label: "Mr" },
                  { value: "Dr", label: "Dr" },
                  { value: "Prof", label: "Prof" },
                ]}
              />
            )}
          />
        </FormField>
        <FormField
          label="Patient Name"
          required={true}
          errors={errors}
          names={["firstName", "lastName"]}
        >
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col">
              <Controller
                name="firstName"
                control={formControl}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Input props={{ ...field, type: "text" }} />
                )}
              />
              <label className="text-xs mt-1">First Name</label>
            </div>
            <div className="flex flex-col mt-2 sm:mt-0 sm:ml-2">
              <Controller
                name="lastName"
                control={formControl}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <Input props={{ ...field, type: "text" }} />
                )}
              />
              <label className="text-xs mt-1">Last Name</label>
            </div>
          </div>
        </FormField>
        <FormField
          label="Gender"
          required={true}
          errors={errors}
          names={["gender"]}
        >
          <RadioInput
            name="gender"
            required="This field is required"
            register={register}
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
          />
        </FormField>
        <FormField label="Age" required={true} errors={errors} names={["age"]}>
          <Controller
            name="age"
            control={formControl}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <Input
                props={{ ...field, type: "number", placeholder: "e.g. 23" }}
              />
            )}
          />
        </FormField>
        <FormField
          label="Medical Aid Name"
          required={true}
          errors={errors}
          names={["medicalAidName"]}
        >
          <Controller
            name="medicalAidName"
            control={formControl}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => <Input props={{ ...field, type: "text" }} />}
          />
        </FormField>
        <FormField
          label="Medical Aid Option / Plan"
          required={true}
          errors={errors}
          names={["medicalAidPlan"]}
        >
          <Controller
            name="medicalAidPlan"
            control={formControl}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => <Input props={{ ...field, type: "text" }} />}
          />
        </FormField>
        <FormField
          label="Medical Aid Number"
          required={true}
          errors={errors}
          names={["medicalAidNumber"]}
        >
          <Controller
            name="medicalAidNumber"
            control={formControl}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => <Input props={{ ...field, type: "text" }} />}
          />
        </FormField>
      </div>
    </FormSection>
  );
};

export default PatientDetails;
