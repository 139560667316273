import dayjs from "dayjs";
import type { NextPage } from "next";
import Head from "next/head";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import Button from "../components/common/Button";
import HorizontalDivider from "../components/common/HorizontalDivider";
import ClinicalDetails from "../components/Forms/ClinicalDetails";
import DoctorDetails from "../components/Forms/DoctorDetails";
import MedicationHistory from "../components/Forms/MedicationHistory";
import PatientDetails from "../components/Forms/PatientDetails";
import PatientEligibility from "../components/Forms/PatientEligibility";
import QualityOfLife from "../components/Forms/QualityOfLife";
import AstellasLogo from "../public/astellas_logo.jpeg";
import Image from "next/image";

const NUMBER_OF_FORM_SECTIONS = 6;

const convertPastPrescribedMedicationDetailsToArray = (data: FieldValues) => {
  const details = data.pastPrescribedMedicationDetails;

  if (details) {
    const newDetails = [];

    for (const key in details) {
      // Ordering matters, we want "name: key" to be overridden if it already exists on the details object
      newDetails.push({
        name: key,
        ...details[key],
      });
    }

    data.pastPrescribedMedicationDetails = newDetails;
  }
};

const addPronouns = (data: FieldValues) => {
  const isFemale = data.gender === "female";

  data.possessivePronoun = isFemale ? "her" : "his";
  data.objectPronoun = isFemale ? "her" : "him";
  data.subjectPronoun = isFemale ? "she" : "he";
  data.capitalSubjectPronoun = isFemale ? "She" : "He";
  data.capitalPossessivePronoun = isFemale ? "Her" : "His";
  data.capitalObjectPronoun = isFemale ? "Her" : "Him";
  data.diagnosisShort =
    data.diagnosis === "Urinary Incontinence (UI)" ? "UI" : "OAB";
};

const consolidateOtherAnticholinergicMedicationDetails = (
  data: FieldValues
) => {
  const details = data.anticholinergicMedicationDetails;

  if (details) {
    const otherMedications = Object.keys(details).filter((key) =>
      key.includes("other")
    );

    for (const otherMedicationKey of otherMedications) {
      const medicationName = otherMedicationKey.replace("other", "");
      const camelCaseName =
        medicationName[0].toLowerCase() + medicationName.slice(1);

      if (details[camelCaseName]) {
        const otherMedicationOptionIndex = (
          details[camelCaseName] as Array<string>
        ).findIndex(
          (medicationOption) => medicationOption.toLowerCase() === "other"
        );

        if (otherMedicationOptionIndex >= 0) {
          details[camelCaseName][otherMedicationOptionIndex] =
            details[otherMedicationKey];
        }
      }
    }
  }
};

const consolidateOtherPatientSymtoms = (data: FieldValues) => {
  if (data.patientSymptoms && data.otherPatientSymptoms) {
    const patientSymptoms = data.patientSymptoms as Array<string>;
    const otherIndex = patientSymptoms.findIndex(
      (symptom) => symptom.toLowerCase() === "other"
    );

    if (otherIndex >= 0) {
      patientSymptoms[otherIndex] = data.otherPatientSymptoms;
    }
  }
};

const Home: NextPage = () => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    register,
    unregister,
    getValues,
    resetField,
    setValue,
  } = useForm();

  const [formSection, setFormSection] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(false);

  const postProcessing = (data: FieldValues) => {
    data.today = dayjs().format("DD-MM-YYYY");
    convertPastPrescribedMedicationDetailsToArray(data);
    addPronouns(data);
    consolidateOtherAnticholinergicMedicationDetails(data);
    consolidateOtherPatientSymtoms(data);
  };

  const onSubmit = async (data: FieldValues) => {
    setIsSubmitting(true);
    setErrorSubmitting(false);

    const submittedData = { ...data };
    postProcessing(submittedData);

    try {
      const res = await fetch("/api/email", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(submittedData),
      });

      if (res.status === 201) {
        setHasSubmitted(true);
      } else {
        setErrorSubmitting(true);
      }
    } catch {
      setErrorSubmitting(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onNext = async () => {
    const isFormValid = await trigger();
    if (isFormValid) {
      setFormSection(formSection + 1);
    }
  };

  const onBack = () => {
    clearErrors();
    setFormSection(formSection - 1);
  };

  const isNotRecommended = !!errors.notRecommended;

  return (
    <div className={"min-h-screen w-full"}>
      <Head>
        <title>Betmiga Motivation Form</title>
        <meta name="description" content="Betmiga Motivation Letter Form" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="flex items-start justify-center">
        <div className="w-full md:py-12 md:px-24 lg:w-9/12 2xl:w-6/12">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col md:shadow-md p-8 bg-white transition-all duration-300"
          >
            {!hasSubmitted && (
              <>
                {formSection >= 1 && (
                  <div className={formSection === 1 ? "block" : "hidden"}>
                    <PatientEligibility
                      watch={watch}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      resetField={resetField}
                      register={register}
                    />
                  </div>
                )}
                {formSection >= 2 && (
                  <div className={formSection === 2 ? "block" : "hidden"}>
                    <PatientDetails
                      formControl={control}
                      errors={errors}
                      register={register}
                    />
                  </div>
                )}
                {formSection >= 3 && (
                  <div className={formSection === 3 ? "block" : "hidden"}>
                    <DoctorDetails formControl={control} errors={errors} />
                  </div>
                )}
                {formSection >= 4 && (
                  <div className={formSection === 4 ? "block" : "hidden"}>
                    <ClinicalDetails
                      formControl={control}
                      errors={errors}
                      watch={watch}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      unregister={unregister}
                    />
                  </div>
                )}
                {formSection >= 5 && (
                  <div className={formSection === 5 ? "block" : "hidden"}>
                    <QualityOfLife formControl={control} errors={errors} />
                  </div>
                )}
                {formSection >= 6 && (
                  <div className={formSection === 6 ? "block" : "hidden"}>
                    <MedicationHistory
                      formControl={control}
                      errors={errors}
                      register={register}
                      unregister={unregister}
                      watch={watch}
                      setValue={setValue}
                    />
                  </div>
                )}
              </>
            )}
            {!isNotRecommended && (
              <>
                {!hasSubmitted && <HorizontalDivider />}
                <div className="flex items-center justify-end md:justify-start space-x-2 mt-4">
                  {formSection > 1 && !hasSubmitted && (
                    <Button onClick={onBack} title="Back" type="button" />
                  )}
                  {formSection < NUMBER_OF_FORM_SECTIONS && (
                    <Button onClick={onNext} title="Next" type="button" />
                  )}
                  {formSection === NUMBER_OF_FORM_SECTIONS && (
                    <>
                      {!hasSubmitted && (
                        <>
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            title="Submit"
                          />
                          {errorSubmitting && (
                            <span className="text-xs sm:text-sm ml-4 text-red-500">
                              There was a problem generating your motivation,
                              please try again later.
                            </span>
                          )}
                        </>
                      )}
                      {hasSubmitted && (
                        <div className="min-h-full w-full flex flex-col items-center justify-start mb-14 text-center">
                          <div className="relative h-24 sm:h-40 w-40 sm:w-60">
                            <Image
                              src={AstellasLogo}
                              alt={"Astellas Logo"}
                              layout={"fill"}
                              objectFit={"contain"}
                            />
                          </div>
                          <p className="text-lg sm:text-xl lg:text-2xl font-bold my-2">
                            Thank You!
                          </p>
                          <p className="my-2 text-xs sm:text-sm md:text-base">
                            Your submission has been received.
                          </p>
                          <p className="my-2 text-xs sm:text-sm md:text-base">
                            Please check your inbox for your completed
                            motivation letter.
                          </p>
                          <p className="my-2 text-xs">BET_2019_0021_ZA</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};

export default Home;
