import { ReactNode } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";

interface FormFieldProps {
  label: string;
  required?: boolean;
  errors?: FieldErrors<FieldValues>;
  names?: string[];
  smallErrorMessageOffset?: boolean; // When there are multiple names provided, and the second name only has a small indent
  children: ReactNode;
}

const FormField = ({
  label,
  required = false,
  errors,
  names,
  smallErrorMessageOffset = false,
  children,
}: FormFieldProps) => {
  const displayError = () => {
    if (errors && names) {
      let nameIndex = 0;

      for (const name of names) {
        const splitNames = name.split(".");

        let associatedErrorObject: any = null;

        splitNames.forEach((splitName, index) => {
          if (associatedErrorObject) {
            associatedErrorObject = associatedErrorObject[splitName];
          } else if (index === 0) {
            associatedErrorObject = errors[splitName];
          }
        });

        if (associatedErrorObject && associatedErrorObject.message) {
          return (
            <div
              className={`relative mt-2 ${
                nameIndex > 0
                  ? !smallErrorMessageOffset
                    ? "md:ml-56 md:pl-2"
                    : "ml-8"
                  : ""
              }`}
            >
              <div className="flex items-center text-xs text-white my-1 bg-primary-light w-fit rounded-md p-2 pl-2 pr-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>
                {associatedErrorObject.message}
              </div>
              <div
                className={`absolute inset-y-0 h-0 w-0 border-8 border-transparent border-b-primary-light -top-3 ${
                  nameIndex > 0 && !smallErrorMessageOffset
                    ? "md:left-4"
                    : "left-2"
                }`}
              />
            </div>
          );
        }

        nameIndex++;
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col mb-5 w-full">
      <label className="font-semibold text-xs sm:text-sm">
        {label}
        {required && <span className="text-red-500">&nbsp;*</span>}
      </label>
      <div className="pt-2 w-full">{children}</div>
      {displayError()}
    </div>
  );
};

export default FormField;
