import { HTMLProps, useEffect, useRef, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";

interface InputProps {
  props: HTMLProps<HTMLInputElement> & ControllerRenderProps;
}

const Input = ({ props }: InputProps) => {
  const [isPrefixedInputFocused, setIsPrefixedInputFocused] = useState(false);

  const onFocus = () => setIsPrefixedInputFocused(true);
  const onBlur = () => setIsPrefixedInputFocused(false);

  const prefix = props.prefix;

  if (prefix) {
    return (
      <div
        className={`flex items-center border border-gray-300 p-2 text-xs sm:text-sm w-full sm:w-56 rounded-md ${
          props.className ? props.className : ""
        } ${
          isPrefixedInputFocused
            ? "outline outline-2 outline-primary border-transparent"
            : ""
        }`}
      >
        <span className="text-gray-400 mr-1">{prefix}</span>
        <input
          {...props}
          className="border-none outline-none flex-grow w-full"
          autoCorrect={"off"}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }

  return (
    <input
      {...props}
      autoCorrect={"off"}
      className={`border border-gray-300 p-2 text-xs sm:text-sm w-full sm:w-56 rounded-md focus:outline-primary hide-arrows ${
        props.className ? props.className : ""
      }`}
    />
  );
};

export default Input;
