import { ChangeEvent, SyntheticEvent } from "react";
import { UseFormRegister, FieldValues, ChangeHandler } from "react-hook-form";

export interface CheckboxOption {
  value: string | number;
  label: string;
}

interface CheckboxInputProps {
  options: CheckboxOption[];
  name: string;
  required: string | boolean;
  register: UseFormRegister<FieldValues>;
  onValueChange?(event: ChangeEvent<HTMLInputElement>): void;
}

// "react-hook-form" Controller doesn't work properly with multiple
// checkbox inputs, so need to register the inputs manually
const CheckboxInput = ({
  options,
  name,
  required,
  register,
  onValueChange,
}: CheckboxInputProps) => {
  const handleOnChange = async (
    event: ChangeEvent<HTMLInputElement>,
    onChange: ChangeHandler
  ) => {
    if (onValueChange) {
      onValueChange(event);
    }
    await onChange(event);
  };

  const registerInput = (option: CheckboxOption) => {
    const { onChange, ...otherFields } = register(name, { required });

    return (
      <div className="relative flex items-center">
        <input
          {...otherFields}
          onChange={(event) => handleOnChange(event, onChange)}
          name={name}
          id={`${name}-${option.value}`}
          type={"checkbox"}
          value={option.value}
          className={
            "appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white cursor-pointer checked:bg-primary checked:border-primary transition duration-200 focus:outline-none focus:border-primary"
          }
        />
        <div className="absolute inset-y-0">
          <svg
            className="fill-white h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 39 39"
          >
            <path d="M5 16.577l2.194-2.195 5.486 5.484L24.804 7.743 27 9.937l-14.32 14.32z" />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <fieldset className="flex flex-col ml-2">
      {options.map((option: CheckboxOption) => (
        <label
          className="text-xs sm:text-sm my-1 flex items-center cursor-pointer"
          htmlFor={`${name}-${option.value}`}
          key={option.value}
        >
          {registerInput(option)}
          <span className="ml-2">{option.label}</span>
        </label>
      ))}
    </fieldset>
  );
};

export default CheckboxInput;
