import { HTMLProps } from "react";
import Loader from "./Loader";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  title: string;
  loading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { className, loading, disabled, title, ...otherProps } = props;
  return (
    <button
      {...otherProps}
      disabled={disabled}
      className={`w-24 px-3 p-2 text-xs sm:text-base border border-primary-light bg-primary text-white rounded ${
        className ? className : ""
      } ${loading || disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      {!loading ? title : <Loader />}
    </button>
  );
};

export default Button;
