import dayjs from "dayjs";
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  SyntheticEvent,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import {
  Control,
  ControllerRenderProps,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import CustomPartFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(CustomPartFormat);

interface DatePickerProps {
  name: string;
  required: string | boolean;
  defaultValue?: string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  formControl: Control;
}

const DATE_FORMAT = "DD-MM-YYYY";

const CustomInput = forwardRef(
  ({ value, onClick }: any, ref: ForwardedRef<HTMLButtonElement>) => (
    <button
      className={`border border-gray-300 p-2 text-xs sm:text-sm w-full sm:w-56 rounded-md focus:outline-primary hide-arrows text-left ${
        value ? "" : "text-gray-400"
      }`}
      onClick={(event: SyntheticEvent) => {
        event.preventDefault();
        onClick(event);
      }}
      ref={ref}
    >
      {value ? value : DATE_FORMAT}
    </button>
  )
);

const DatePicker = ({
  name,
  required,
  register,
  setValue,
  formControl,
}: DatePickerProps) => {
  const defaultValue = useWatch({ control: formControl, name });

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    defaultValue ? dayjs(defaultValue, DATE_FORMAT).toDate() : undefined
  );
  const { onChange, ...otherFields } = register(name, { required });

  const handleChange = (date: Date, event: SyntheticEvent) => {
    event.preventDefault();
    setSelectedDate(date);

    // react hook form doesn't play nice with react date picker so have to set value manually
    // rather than relying on change event
    setValue(name, dayjs(date).format(DATE_FORMAT));
  };

  return (
    <ReactDatePicker
      {...otherFields}
      selected={selectedDate}
      onChange={handleChange}
      dateFormat="dd-MM-yyyy"
      placeholderText={DATE_FORMAT}
      autoComplete="off"
      showPopperArrow={false}
      showYearDropdown={true}
      customInput={<CustomInput />}
      className="cursor-pointer block border border-gray-300 text-xs sm:text-sm p-2 w-full sm:w-56 rounded-md focus:outline-primary"
    />
  );
};

export default DatePicker;
