import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import FormField from "../common/FormField";
import FormSection from "../common/FormSection";
import Input from "../common/Input";
import Select from "../common/Select";

interface DoctorDetailsProps {
  formControl: Control;
  errors: FieldErrors<FieldValues>;
}

const PAGE_TITLE = "Doctor Details";
const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const DoctorDetails = ({ formControl, errors }: DoctorDetailsProps) => {
  return (
    <FormSection title={PAGE_TITLE}>
      <FormField
        required={true}
        label={"HCP Title"}
        errors={errors}
        names={["hcpTitle"]}
      >
        <Controller
          name="hcpTitle"
          control={formControl}
          defaultValue=""
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Select
              props={{ ...field }}
              options={[
                { value: "Dr", label: "Dr" },
                { value: "Prof", label: "Prof" },
                { value: "Mr", label: "Mr" },
                { value: "Ms", label: "Ms" },
              ]}
            />
          )}
        />
      </FormField>
      <FormField
        label="HCP Name"
        required={true}
        errors={errors}
        names={["hcpFirstName", "hcpLastName"]}
      >
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col">
            <Controller
              name="hcpFirstName"
              control={formControl}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Input props={{ ...field, type: "text" }} />
              )}
            />
            <label className="text-xs mt-1">First Name</label>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0 sm:ml-2">
            <Controller
              name="hcpLastName"
              control={formControl}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Input props={{ ...field, type: "text" }} />
              )}
            />
            <label className="text-xs mt-1">Last Name</label>
          </div>
        </div>
      </FormField>
      <FormField
        label="HCPSA Registration Number"
        required={true}
        errors={errors}
        names={["hcpsaNumber"]}
      >
        <Controller
          name="hcpsaNumber"
          control={formControl}
          defaultValue=""
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Input props={{ ...field, type: "text", prefix: "MP" }} />
          )}
        />
      </FormField>
      <FormField
        label="Practice Number"
        required={true}
        errors={errors}
        names={["practiceNumber"]}
      >
        <Controller
          name="practiceNumber"
          control={formControl}
          defaultValue=""
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <Input props={{ ...field, type: "text", prefix: "PR" }} />
          )}
        />
      </FormField>
      <FormField
        label="Doctor email address for proposed motivation"
        required={true}
        errors={errors}
        names={["doctorEmail"]}
      >
        <Controller
          name="doctorEmail"
          control={formControl}
          defaultValue=""
          rules={{
            required: "This field is required",
            pattern: {
              value: EMAIL_REGEX,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => (
            <Input
              props={{
                ...field,
                type: "email",
                placeholder: "example@example.com",
              }}
            />
          )}
        />
      </FormField>
    </FormSection>
  );
};

export default DoctorDetails;
